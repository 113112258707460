import "../../style.css";
export default function ShowHeader() {
    return (
        <header className="splashPage">
            <div className="splashPageImg">
                <Home />
            </div>
        </header>
    );
}
// home page
function Home() {
    return (
        <nav>
            <a href="https://www.twitch.tv/jonahveil" className="logo">
                <h1 className="homeHeader">
                    <span className="jab">JonahVeil</span>
                    
                </h1>
            </a>
            <ul className="homeUL">
                <li className="nav-item">
                    <a href="/guides" className="nav-link" id="nav-link">
                        Guides
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/socials" className="nav-link" id="nav-link">
                        Socials
                    </a>
                </li>
                <li className="nav-item">
                    <a href="/builds" className="nav-link" id="nav-link">
                        Builds
                    </a>
                </li>
            </ul>
        </nav>
    );
}