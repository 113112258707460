import { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import "./style.css";
import ShowHeader  from "../src/pages/header/header.js";
// using lazy
const Socials = lazy(() => import("./pages/classdata/Socials"));
const Guides = lazy(() => import("./pages/classdata/Guides"));
const Builds = lazy(() => import("./pages/classdata/Builds"));

function App() {
  return (
    <>
          <Routes>
              <Route exact path="/" element={<ShowHeader />} />
              <Route path="/*" element={<ShowHeader />} />
              <Route path="/guides" element={<Guides />} />
              <Route path="/socials" element={<Socials />} />
              <Route path="/builds" element={<Builds />} />
          </Routes>
    </>
  );
}

export default App;
